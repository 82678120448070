import { createRouter, createWebHistory } from 'vue-router';
import AuthService from '@/services/auth.service';

async function guardMyroute(to, from, next) {
  let isAuthenticated = await AuthService.isAuthenticated();
  if (isAuthenticated) {
    next();
  } else {
    next('/');
  }
}

const routes = [
  { path: '/', name: 'login', component: () => import('../views/LoginView.vue') },
  { path: '/overtime', name: 'Overtime', beforeEnter : guardMyroute, component: () => import('../views/OvertimeView.vue') },
  { path: '/overtime/:requestId', name: 'EditOvertime', beforeEnter : guardMyroute, component: () => import('../views/OvertimeView.vue') },
  { path: '/oncall', name: 'Oncall', beforeEnter : guardMyroute, component: () => import('../views/OncallView.vue') },
  { path: '/oncall/:requestId', name: 'EditOncall', beforeEnter : guardMyroute, component: () => import('../views/OncallView.vue') },
  { path: '/logs', name: 'Logs', beforeEnter : guardMyroute, component: () => import('../views/LogsView.vue') },
  { path: '/approve', name: 'approve', beforeEnter : guardMyroute, component: () => import('../views/ApproveView.vue') },
  { path: '/holidays', name: 'holidays', beforeEnter : guardMyroute, component: () => import('../views/HolidaysView.vue') },
  { path: '/settings', name: 'settings', beforeEnter : guardMyroute, component: () => import('../views/SettingsView.vue') },
  { path: '/payroll', name: 'payroll', beforeEnter : guardMyroute, component: () => import('../views/PayrollView.vue') },
  { path: '/history', name: 'History', beforeEnter : guardMyroute, component: () => import('../views/HistoryView.vue') },
  { path: '/allrates', name: 'Allrates', beforeEnter : guardMyroute, component: () => import('../views/AllratesView.vue') },
  { path: '/maintenance', name: 'Maintenance', beforeEnter : guardMyroute, component: () => import('../views/MaintenanceView.vue') },
  { path: '/:pathMatch(.*)*', name: 'Notfound', component: () => import('../views/PageNotFoundView.vue') }
];

let router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
